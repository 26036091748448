import React, { useEffect } from 'react';
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from 'react-router-dom';
import LoginPage from './components/Pages/LoginPage';
import DashboardPage from './components/Pages/DashboardPage';
import NavBar from './components/Layout/NavBar';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase-config';

const PageWrapper = () => (
  <>
    <NavBar />
    <Outlet />
  </>
);

const App = () => {
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User is logged in', user);
        user.getIdToken(true).then((token) => {
          localStorage.setItem('token', token);
        });
      } else {
        // console.log('User is not logged in');
        localStorage.clear();
        // dispatch(loginValuesActions.resetLoginValues());
        // toast.error('Faça login para continuar');
      }
      return unsubscribe;
    });
  }, []);
  const router = createBrowserRouter([
    {
      path: '/',
      element: <PageWrapper />,
      children: [
        { index: true, element: <LoginPage /> },

        {
          path: '/dashboard',
          element: <DashboardPage />,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
};

export default App;
