import { useEffect, useRef, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import {
  LogoutOutlined,
  MenuOutlined,
  PersonOutlineOutlined,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import simboloVitaResize from '../../media/simboloVitaResize.png';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { auth } from '../../firebase-config';

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const userId = '';

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleOpenMenu = () => {
    setIsMenuOpen(true);
  };

  const avatarMenuRef = useRef(null);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        localStorage.clear();
        toast.success('Logout efetuado com sucesso!');
        setIsMenuOpen(false);
        navigate('/');
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  };

  return (
    <nav className='flex items-center border-b justify-between p-4 bg-sky-200 '>
      <div
        className='flex items-center gap-2 hover:cursor-pointer'
        onClick={() => navigate('/')}
      >
        <img src={simboloVitaResize} alt='logo' className='w-8 h-8' />
        <h1 className='font-semibold text-xl '>Vita Admin</h1>
      </div>

      <div className='hover:cursor-pointer' ref={avatarMenuRef}>
        <Avatar onClick={handleOpenMenu}>
          <PersonOutlineOutlined />
        </Avatar>
        <Menu
          id='simple-menu'
          anchorEl={avatarMenuRef.current}
          //   keepMounted
          open={isMenuOpen}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleLogout}>
            <div className='flex gap-2'>
              <LogoutOutlined />
              Sair
            </div>
          </MenuItem>
        </Menu>
      </div>
    </nav>
  );
};

export default NavBar;
