import { toast } from 'react-toastify';
import useSWR from 'swr';
const url = 'https://adminapi.vita.app.br';
const testUrl = 'http://localhost:3333';

export const getUsers = async (token) => {
  try {
    const response = await fetch(`${url}/users`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      response.json().then((data) => {
        toast.error(data.message);
      });

      return;
    }
    // console.log('response: ', response);
    return response.json();
  } catch (error) {
    console.error('Error:', error);
  }
};

export const enableUser = async (userId) => {
  try {
    const response = await fetch(`${url}/users/enable`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    });
    if (!response.ok) {
      response.json().then((data) => {
        toast.error(data.message);
      });

      return;
    }
    // console.log('response: ', response);
    return response.json();
  } catch (error) {
    console.error('Error:', error);
  }
};

export const disableUser = async (userId) => {
  console.log('User ID disableUser:', userId);
  try {
    const response = await fetch(`${url}/users/disable`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    });
    if (!response.ok) {
      response.json().then((data) => {
        toast.error(data.message);
      });

      return;
    }
    // console.log('response: ', response);
    return response.json();
  } catch (error) {
    console.error('Error:', error);
  }
};

export const useGetUsers = (token) => {
  const { data, error, isLoading } = useSWR(
    `usersList`,
    () => getUsers(token),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    usersList: data,
    isLoading,
    isError: error,
  };
};
