import { signInWithEmailAndPassword } from 'firebase/auth';
import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { auth } from '../../firebase-config';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, getValues, setValue, watch } = useForm({
    defaultValues: {},
  });

  const handleLogin = ({ email, password }) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        localStorage.setItem('token', user.getIdToken());
        // console.log('User: ', user);
        toast.success('Login efetuado com sucesso!');
        navigate('/dashboard');
        // ...
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/invalid-email':
            toast.error('Email inválido');
            break;
          case 'auth/user-not-found':
            toast.error('Usuário não encontrado');
            break;
          case 'auth/wrong-password':
            toast.error('Senha incorreta');
            break;
          default:
            break;
        }
        // console.log('Error: ', error.code);
      });
  };

  return (
    <div className='flex flex-col items-center justify-center gap-4'>
      <div className='flex flex-col items-center justify-center gap-4 bg-slate-100  mt-20 border-sky-800 border-2 shadow-lg'>
        <h1 className='text-xl font-semibold mt-12'>LOGIN</h1>
        <div>
          <form
            className='flex flex-col gap-2 p-8 md:p-20'
            onSubmit={handleSubmit(handleLogin)}
          >
            <label htmlFor='email'>Usuário:</label>
            <input
              {...register('email')}
              className='h-10 hover:bg-slate-200 px-4 rounded-md'
              type='text'
              id='email'
              name='email'
              placeholder='E-mail'
            />
            <label htmlFor='password'>Senha:</label>
            <input
              {...register('password')}
              className='h-10 bg-white hover:bg-slate-50 px-4 rounded-md'
              type='password'
              id='password'
              name='password'
              placeholder='Senha'
            />
            <button
              className='p-2 w-20 bg-sky-400 mt-8 m-auto rounded-md font-semibold hover:bg-sky-500 transition-colors duration-300 ease-in-out'
              type='submit'
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
